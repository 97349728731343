<template>
    <div class="d-flex flex-column p-2 w-100 h-100 overflow-y-auto" :class="{'edit-mode': dataObject?.layoutManager.inEditMode, 'dirty-layout': isDirty}">
        <!-- <div v-if="!hideActiveSection && activeLayout && ((activeLayout.name && !activeLayout.isNew) || activeLayout.id)" class="w-100"> -->
            <!-- <h6 class="d-inline-flex" v-if="activeLayout.name && !activeLayout.isNew">{{$t('Applied:')}} {{activeLayoutName}}</h6> -->
            <!-- <template v-if="activeLayout.id"> -->
                <!-- <button class="btn btn-sm btn-link float-end" @click="$refs.propertiesModal?.open()" :title="$t('Laytout properties')"> -->
                    <!-- <i class="bi bi-info-circle"></i> -->
                <!-- </button> -->
                <!-- <LayoutPropertiesModal ref="propertiesModal" :layoutObject="activeLayout" /> -->
            <!-- </template> -->
            <!-- <hr> -->
        <!-- </div> -->

        <h6>{{$t('My Layouts')}}</h6>
        <FieldFilter v-if="dsLayouts.rowCount >= 10" :inputClassName="'form-control form-control-sm'" :filterObject="dsLayouts.filterObject" columnName="Name" hideDropdown hideColumn :placeHolder="$t('Search...')"/>
        <template v-if="personalLayouts.length > 0">
        <div class="layouts-list">
            <div v-for="layout in personalLayouts" 
                class="layout-item"
                :class="{'current': layout.ID === activeLayoutID}">
                <button class="btn btn-sm btn-link ps-0 pe-1"
                    :class="{'invisible': layout.ID !== activeLayoutID || !isDirty || isSaving}"
                    @click="() => save()"
                    :disabled="layout.ID !== activeLayoutID || !isDirty || isSaving"
                    :title="$t('Save layout changes')">
                    <i class="bi bi-check-lg icon-bold"></i>
                </button>
                <button class="btn btn-link text-decoration-none text-truncate p-0 text-start"
                    :title="layout.Description"
                    @click="setLayout(layout)">
                    {{layout.Name}}
                </button>
                <button class="btn btn-sm btn-link"
                    :class="{'invisible': layout.ID !== activeLayoutID || !isDirty || isSaving}"
                    :disabled="layout.ID !== activeLayoutID || !isDirty || isSaving"
                    @click="() => dataObject.layoutManager.reapplyLayout(true, true)"
                    :title="$t('Cancel layout changes')">
                    <i class="bi bi-arrow-counterclockwise text-danger"></i>
                </button>
                <ODropdown>
                    <template #default="{target, open}">
                        <button :ref="target" @click="open" class="btn btn-sm btn-link layouts-hoverable py-0 ms-auto">
                            <i class="bi bi-three-dots"></i>
                        </button>
                    </template>
                    <template #dropdown="{container, close}">
                        <div :ref="container" class="dropdown-menu show">

                                <button class="dropdown-item" @click="() => { openRenameModal(layout); close(); }">
                                    {{$t('Rename')}}
                                    <i class="bi bi-pencil-fill ms-2 float-end"></i>
                                </button>
                                <button v-if="canSetDefaults" class="dropdown-item" @click="() => { setLayoutAsDefault(layout); close();}">
                                    {{$t('Set As Default')}}
                                    <i class="bi bi-play-circle ms-2 float-end"></i>
                                </button> 
                                <button v-if="canShare" class="dropdown-item" @click="() => {$refs.shareModal?.open(layout); close();}">
                                    {{$t('Publish')}}
                                    <i class="bi bi-share-fill ms-2 float-end"></i>
                                </button> 
                                <button class="dropdown-item" @click="() => { openPersonShareModal(layout.ID); close();}">
                                    {{$t('Share With Persons')}}
                                    <i class="bi bi-person-fill ms-2 float-end"></i>
                                </button> 
                        
                                <ActionDelete class="dropdown-item" 
                                    confirm :row="layout">
                                    {{$t('Delete')}}
                                    <i class="bi bi-trash-fill ms-2 float-end"></i>
                                </ActionDelete>
                        </div>
                    </template>
                </ODropdown>
            </div>
        </div>
        </template>
        <template v-else>
            <div class="text-muted">{{$t('No saved layouts')}}</div>
        </template>

        <hr>
        <h6>{{$t('Published Layouts')}}</h6>

        <FieldFilter v-if="dsSharedContextLayouts.rowCount >= 10" :inputClassName="'form-control form-control-sm'" :filterObject="dsSharedContextLayouts.filterObject" columnName="Name" hideDropdown hideColumn :placeHolder="$t('Search...')"/>
        <div class="hstack gap-3 mb-2 mt-1" v-if="hasHidden">
            <div class="form-check form-switch" v-if="hasHidden">
                <input class="form-check-input" type="checkbox" :id="compId+'showHidden'" :checked="showHidden" @click="() => showHidden = !showHidden">
                <label class="form-check-label" :for="compId+'showHidden'">
                    {{$t('Include hidden')}}
                </label>
            </div>
            <!-- <div class="form-check form-switch"> -->
                <!-- <input class="form-check-input" type="checkbox" :id="compId+'restrictToContext'" :checked="restrictSharedToContext" @click="handleRestrictCheckbox"> -->
                <!-- <label class="form-check-label" :for="compId+'restrictToContext'"> -->
                    <!-- {{$t('Restrict to context')}} -->
                <!-- </label> -->
            <!-- </div> -->
            <!-- <div class="form-check form-switch" v-if="canShare"> -->
                <!-- <input class="form-check-input" type="checkbox" :id="compId+'showDefaults'" :checked="showDefaults" @click="handleShowDefaultsCheckbox"> -->
                <!-- <label class="form-check-label" :for="compId+'showDefaults'"> -->
                    <!-- {{$t('Show defaults')}} -->
                <!-- </label> -->
            <!-- </div> -->
        </div>

        <div v-if="dsSharedContextLayouts.state.isLoading" class="d-flex justify-content-center">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <template v-else-if="sharedLayouts.length > 0">
        <div class="layouts-list">
            <div v-for="layout in sharedLayouts" 
                class="layout-item"
                v-show="!layout.Hidden || showHidden"
                :class="{'current': layout.ID === activeLayoutID}">
                <div v-if="canSetDefaults && layout.Default && couldSaveCurrentLayout && !(layout.ID !== activeLayoutID || !isDirty || isSaving)" class="dropdown" style="display: contents;">
                    <button class="btn btn-sm btn-link ps-0 pe-1"
                        :disabled="layout.ID !== activeLayoutID || !isDirty || isSaving"
                        data-bs-toggle="dropdown"
                        :title="$t('Save layout changes')">
                        <i class="bi bi-check-lg icon-bold"></i>
                    </button>
                    <ul class="dropdown-menu">
                        <li><button @click="() => save(true)" class="dropdown-item">{{ $t('Save Changes') }}</button></li>
                        <li><button @click="() => save()" class="dropdown-item">{{ $t('Save As New ') }}</button></li>
                    </ul>
                </div>
                <button v-else-if="couldSaveCurrentLayout && !(layout.ID !== activeLayoutID || !isDirty || isSaving)"
                    class="btn btn-sm btn-link ps-0 pe-1"
                    @click="() => save()"
                    :disabled="layout.ID !== activeLayoutID || !isDirty || isSaving"
                    :title="$t('Save layout changes')">
                    <i class="bi bi-check-lg icon-bold"></i>
                </button>
                <button class="btn btn-link text-decoration-none text-truncate p-0 text-start"
                    :class="{'text-decoration-line-through': layout.Hidden}"
                    :title="layout.Description"
                    @click="setLayout(layout, layoutIsContextual(layout) ? 'context' : 'persons')">
                    <i v-if="!layoutIsContextual(layout)" v-person-hover="layout.Person_ID" class="bi bi-person"></i>
                    <i v-else-if="layout.Default" class="bi bi-play-circle"></i>
                    <i v-else v-tooltip="layout.OrgUnit" class="bi bi-share"></i>
                    {{layout.Name}} <span v-if="layout.OrgUnitName">[{{ layout.OrgUnitName }}]</span>
                </button>
                <button class="btn btn-sm btn-link"
                    v-if="couldSaveCurrentLayout"
                    :class="{'invisible': layout.ID !== activeLayoutID || !isDirty || isSaving}"
                    :disabled="layout.ID !== activeLayoutID || !isDirty || isSaving"
                    @click="() => dataObject.layoutManager.reapplyLayout(true, true)"
                    :title="$t('Cancel layout changes')">
                    <i class="bi bi-arrow-counterclockwise text-danger"></i>
                </button>
                <ODropdown v-if="canShare && layoutIsContextual(layout) && canShareMap[layout.ID]">
                    <template #default="{target, open}">
                        <button :ref="target" @click="open" class="btn btn-sm btn-link layouts-hoverable py-0 ms-auto">
                            <i class="bi bi-three-dots"></i>
                        </button>
                    </template>
                    <template #dropdown="{container, close}">
                        <div :ref="container" class="dropdown-menu show">
                            <button v-if="!layout.Default" class="dropdown-item" @click="() => { openRenameModal(layout, true); close(); }">
                                {{$t('Rename')}}
                                <i class="bi bi-pencil-fill ms-2 float-end"></i>
                            </button>
                            <button v-if="!layout.Default && canSetDefaults" class="dropdown-item" @click="() => { setLayoutAsDefault(layout); close();}">
                                {{$t('Set As Default')}}
                                <i class="bi bi-play-circle ms-2 float-end"></i>
                            </button> 
                            <!-- <button class="dropdown-item" @click="() => { enterEditMode(layout); close(); }"> -->
                                <!-- {{$t('Edit')}} -->
                                <!-- <i class="bi bi-pencil-square ms-2 float-end"></i> -->
                            <!-- </button> -->
                            <ActionDelete class="dropdown-item" v-if="!layout.Default"
                                :title="$t('Deleting layout will remove it for all users')"
                                confirm :row="layout">
                                {{$t('Delete')}}
                                <i class="bi bi-x-lg ms-2 float-end"></i>
                            </ActionDelete>
                            <ActionDelete class="dropdown-item" v-else-if="canSetDefaults"
                                :title="$t('Delete default layout')"
                                confirm :row="layout">
                                {{$t('Delete')}}
                                <i class="bi bi-x-lg ms-2 float-end"></i>
                            </ActionDelete>
                            <!-- <OActionConfirm v-else class="dropdown-item text-danger" :confirmOptions="{ -->
                                    <!-- title: $t('Delete default layout'), -->
                                    <!-- message: $t('Are you sure you want to delete the default layout for orgunit, This will remove all perosnal override layouts from all users on this layout as well'), -->
                                    <!-- btnTextOk: $t('Delete'), -->
                                    <!-- btnTextCancel: $t('Cancel'), -->
                                    <!-- backdrop: true -->
                                <!-- }" @confrim="deleteDefaultLayout(layout)" -->
                                <!-- :title="$t('Deleting a default layout will also delete all personal overrides on this layout for all users')"> -->
                                <!-- {{$t('Delete')}} -->
                                <!-- <i class="bi bi-x-lg ms-2 float-end"></i> -->
                            <!-- </OActionConfirm> -->
                            <button class="dropdown-item" @click.passive="() => { setLayoutAsHidden(layout); close(); }"
                                :title="layout.Hidden ? $t('Unhide layout for yourself') : $t('Hide layout for yourself')">
                                {{layout.Hidden ? $t('Unhide') : $t('Hide')}}
                                <i class="bi ms-2 float-end" :class="layout.Hidden ? 'bi-eye' : 'bi-eye-slash'"></i>
                            </button>
                        </div>
                    </template>
                </ODropdown>

                <button v-else-if="!layoutIsContextual(layout)" v-tooltip="$t('Unshare with me')" :disabled="isUnsharing" @click.passive="unshare(layout)" class="btn btn-sm btn-link layouts-hoverable py-0">
                    <i class="bi bi-x-lg"></i>
                </button>
                <button v-else class="btn btn-sm btn-link layouts-hoverable py-0" 
                    v-tooltip="layout.Hidden ? $t('Unhide layout') : $t('Hide layout')"
                    @click.passive="setLayoutAsHidden(layout)">
                    <i class="bi" :class="layout.Hidden ? 'bi-eye' : 'bi-eye-slash'"></i>
                </button>

            </div>
        </div>
        </template>
        <template v-else>
            <span class="text-muted">{{$t('No published layouts')}}</span>
        </template>
    
        <div class="vstack border-top pt-2 mt-2">
            <div class="hstack flex-wrap">
                <!-- <template v-if="couldSaveCurrentLayout"> -->
                    <!-- <button v-if="activeLayout" :disabled="!isDirty || isSaving" class="btn btn-sm me-2 mb-1" -->
                        <!-- :class="isDirty ? 'btn-primary' : 'btn-outline-primary'" @click="save" -->
                        <!-- :title="$t('Save layout changes')"> -->
                        <!-- {{$t('Save')}} -->
                    <!-- </button> -->
                    <!-- <button v-if="isDirty" :disabled="isSaving" class="btn btn-outline-primary btn-sm me-2 mb-1" -->
                        <!-- :title="$t('Cancel layout changes')" @click="dataObject.layoutManager.reapplyLayout(true, true)"> -->
                        <!-- {{$t('Cancel Changes')}} -->
                    <!-- </button> -->
                <!-- </template> -->
                <button class="btn  btn-outline-primary btn-sm me-2 mb-1" :disabled="isSaving" @click="createNewLayout()"
                    :title="$t('Create a new layout')">
                    {{$t('New Layout')}}
                </button>
                <button class="btn  btn-outline-primary btn-sm me-2 mb-1" v-if="canBeSavedAs" @click="saveAsNew()"
                    :title="$t('Create a copy of the current layout')" :disabled="isSaving">
                    {{$t('Save As')}}
                </button>
                <button v-if="activeLayout && !activeLayout.isDefault" class="btn btn-outline-primary btn-sm me-2 mb-1" :disabled="isSaving" @click="resetLayout()"
                    :title="$t('Reset to default layout')">
                    {{$t('Reset To Default')}}
                </button>
                <button v-if="dataObject?.layoutManager && !dataObject.layoutManager.activeLayoutValid" class="btn btn-primary btn-sm me-2 mb-1"  @click="dataObject.layoutManager.reapplyLayout()"
                    :title="$t('Reapply the current layout')">
                    {{$t('Apply')}}
                </button>
                <!-- <button v-if="dataObject.layoutManager.inEditMode" class="btn btn-primary btn-sm me-2 mb-1" @click="dataObject.layoutManager.enterEditMode(false)" -->
                    <!-- :title="$t('End layout editing')"> -->
                    <!-- {{$t('End Edit')}} -->
                <!-- </button> -->
            </div>

            <div v-if="dataObject?.layoutManager && !dataObject.layoutManager.activeLayoutValid" class="hstack">
                <i class="bi bi-exclamation-triangle-fill text-warning mx-1" :title="$t('Currently applied layout is outdated, reapply layout to continue tracking changes.')"></i>
                <b class="text-truncate">{{$t('Newer layout version found, saving is disabled until it is applied')}}</b>
            </div>

            <!-- <div class="text-muted"> -->
                <!-- <p class="mb-1"> -->
                    <!-- {{$t('Layouts are autosaved on any tracked property change.')}} -->
                <!-- </p> -->
                <!-- <ul> -->
                    <!-- <li>{{$t('New Layout - create a new empty layout.')}}</li> -->
                    <!-- <li v-if="canBeSavedAs">{{$t('Save As - create a copy of the currently applied layout.')}}</li> -->
                    <!-- <li v-if="activeLayout && activeLayout.id">{{$t('Reset layout - unset the current layout. If the applied layout is a default one then the next closest default layout will be applied.')}}</li> -->
                <!-- </ul> -->
            <!-- </div> -->

        </div>


        <OModal ref="renameModal" :title="$t('Rename layout')" @hidden="renameModalClosed">
            <div class="modal-body">
                <label>{{$t('Layout name')}}</label>
                <input v-model="dsSharedContextLayouts.current.Name" v-if="renamingShared"
                    class="form-control form-control-sm mb-1">
                <input v-model="dsLayouts.current.Name" v-else
                    class="form-control form-control-sm mb-1">
                <label>{{$t('Layout description')}}</label>
                <OTextArea v-model="dsSharedContextLayouts.current.Description" v-if="renamingShared"
                    class="form-control form-control-sm"
                    style="max-height: 100px;"
                    :rows="2" auto-grow no-resize/>
                <OTextArea v-model="dsLayouts.current.Description" v-else
                    class="form-control form-control-sm"
                    style="max-height: 100px;"
                    :rows="2" auto-grow no-resize/>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">{{$t('Cancel')}}</button>
                <button type="button" :disabled="renamingShared ? !dsSharedContextLayouts.current.Name : !dsLayouts.current.Name" @click="renameLayout" class="btn btn-primary btn-sm">{{$t('Rename')}}</button>
            </div>
        </OModal>

        <LayoutShareModal ref="shareModal" :dataObject="dataObject" @shared="dsSharedContextLayouts.load()"/>
        <LayoutShareWithPersonModal v-if="personShareLayoutId" ref="personShareModal" :layoutId="personShareLayoutId" @hidden="personShareLayoutId = null" noButton />
    </div>
</template>

<script lang="ts">
import type DataObject from 'o365.modules.DataObject.ts';

export interface ILayoutsListProps {
    dataObject: DataObject
    hideActiveSection?: boolean
}
</script>

<script setup lang="ts">
import { LayoutType } from 'o365.modules.DataObject.Layout.ts';
import LayoutPropertiesModal from 'o365.vue.components.Layouts.PropertiesModal.vue';
import LayoutShareWithPersonModal from 'o365.vue.components.Layouts.ShareWithPerson.vue';
import { SharingCapabilitiesChecker, procSetLayoutAsHidden, procUnsetLayoutAsHidden } from 'o365.modules.DataObject.Layout.UpdatesRetriever.ts';
import { ref, computed, watch, nextTick } from 'vue';
import { getOrCreateDataObject } from 'o365.vue.ts';
import context from 'o365.modules.Context.ts';
import { userSession } from 'o365.modules.configs.ts';
import { app } from 'o365.modules.configs.ts'
import ODropdown from 'o365.vue.components.DropDown.vue';
import OModal from 'o365.vue.components.Modal.vue';
import OTextArea from 'o365.vue.components.TextArea.vue';
import ActionDelete from 'o365.vue.components.Action.Delete.vue';
import LayoutShareModal from 'o365.vue.components.Layouts.Sharing.vue';
import FieldFilter from 'o365.vue.components.FieldFilter.vue';
import { useDataObjectEventListener } from 'o365.vue.composables.EventListener.ts';
import vPersonHover from 'o365.vue.directive.personHover.ts';
import vTooltip from 'o365.vue.directive.tooltip.ts';
import $t from 'o365.modules.translate.ts';
import logger from 'o365.modules.Logger.ts';
import OActionConfirm from 'o365.vue.components.Action.Confirm.vue';

const props = defineProps<ILayoutsListProps>();

const compId = computed(() => {
    return `${props.dataObject.id}_layoutsList`;
});

function asyncAlert(...args: any[]) {
    return import('o365.controls.alert.ts').then((services) => services.default(...args));
}

function asyncConfirm(...args: any[]) {
    return import('o365.controls.confirm.ts').then((services) => services.default(...args));
}

const savingAs = ref(false);
const isUnsharing = ref(false);
const personShareModal = ref(null);
const personShareLayoutId = ref(null);
const showHidden = ref(false);

const isDirty = computed(() => {
    return activeLayout.value && activeLayout.value.hasChanges();
});

const couldSaveCurrentLayout = computed(() => {
    if (activeLayout.value) {
        if (LayoutType.SharedFromPerson == activeLayout.value.layoutType) {
            return false;
        } else if ([LayoutType.SharedDefault, LayoutType.Shared, LayoutType.SharedPersonalDefault].includes(activeLayout.value.layoutType)) {
            return canShare.value && canShareMap.value[activeLayout.value.id!]
        } else {
            return true;
        }
    } else {
        return false;
    }
});

const hasHidden = computed(() => sharedLayouts.value.some(layout => layout.Hidden));
const isCreatingNewLayout = ref(false);

const isSaving = computed(() => {
    return activeLayout.value?.isSaving || savingAs.value || isCreatingNewLayout.value;
});
// const canBeSaved = computed(() => {
    // return (props.dataObject.layoutManager?.canSave ?? false) && saveSettings.value?.filter(x => !x.hide && x.include).length;
// });
const canBeSavedAs = computed(() => {
    return (props.dataObject.layoutManager?.canSaveAs ?? false) && activeLayout.value && (Object.keys(activeLayout.value.layout).length || (!activeLayout.value.id && isDirty.value));
});

const activeLayout = computed(() => {
    return props.dataObject.layoutManager?.hasActiveLayout ? props.dataObject.layoutManager.activeLayout : null;
});

const saveSettings = ref<{
    key: string;
    caption: string;
    include: boolean;
    hide: boolean;
}[]>([]);

watch(() => activeLayout.value, (newLayout) => {
    saveSettings.value = [];
    if (newLayout) {
        newLayout.modulesArray.forEach(layoutModule => {
            switch (layoutModule.key) {
                case 'columns':
                    saveSettings.value.push({
                        key: layoutModule.key,
                        caption: $t('Columns'),
                        include: true,
                        hide: true
                    });
                    break;
                case 'sortBy':
                    saveSettings.value.push({
                        key: layoutModule.key,
                        caption: $t('Sort by'),
                        include: false,
                        hide: false
                    });
                    break;
                case 'filter':
                    saveSettings.value.push({
                        key: layoutModule.key,
                        caption: $t('Filter'),
                        include: false,
                        hide: false
                    });
                    break;
                case 'groupby':
                    saveSettings.value.push({
                        key: layoutModule.key,
                        caption: $t('Groupby'),
                        include: false,
                        hide: false
                    });
                    break;
                case 'properties':
                    saveSettings.value.push({
                        key: layoutModule.key,
                        caption: $t('Property columns'),
                        include: true,
                        hide: true
                    });
                    break;
            }
        });
    }
},   { immediate: true });

/** Computed active layout based on the type of the layout */
const activeLayoutName = computed(() => {
    return activeLayout.value?.layoutType === LayoutType.PersonalDefault ? 'User configured layout' : activeLayout.value?.name;
});


//--- Layouts DS ---
const getFields = () => {
    return ['ID', 'Name', 'Description', 'DataObject_ID', 'App_ID', 'Person_ID', 'OrgUnit_ID', 'Updated', 'AccessIdPath', 'OrgUnit', 'Hidden', 'OrgUnitName']
        .map(field => ({
            name: field
        }));
};
const configurableRegister = { isConfigured: false, id: undefined };
const registerClause = configurableRegister.isConfigured ? `[Register_ID] = ${configurableRegister.id}` : '[Register_ID] IS NULL';

const dsLayouts = getOrCreateDataObject({
    id: `o_${props.dataObject.id}_layoutsList`,
    viewName: 'sviw_O365_MyLayouts2',
    uniqueTable: 'stbv_O365_Layouts',
    fields: getFields(),
    disableLayouts: true,
    allowDelete: true,
    allowUpdate: true,
    whereClause: `[DataObject_ID] = '${props.dataObject.id}' AND [App_ID] = '${app.id}'`
        + ` AND ([Default] = 0)`
        + ` AND [Person_ID] = ${userSession.personId}`
        + ` AND ([OrgUnit_ID] = ${context.id} OR [OrgUnit_ID] IS NULL)`
        + ` AND ${registerClause}`
});

const dsSharedContextLayouts = getOrCreateDataObject({
    id: `o_${props.dataObject.id}_sharedContextLayoutsList`,
    viewName: 'sviw_O365_MyLayouts2',
    uniqueTable: 'stbv_O365_Layouts',
    fields: [...getFields(), { name: 'Default', type: 'bit', sortOrder: 1, sortDirection: 'desc' }],
    disableLayouts: true,
    whereClause: `[DataObject_ID] = '${props.dataObject.id}' AND [App_ID] = '${app.id}'`
        + ` AND [Person_ID] IS NULL`
        + ` AND ([OrgUnit_ID] IS NOT NULL OR [Default] = 1)`
        + ` AND ${registerClause}`
});

const dsSharedLayouts = getOrCreateDataObject({
    id: `o_${props.dataObject.id}_sharedLayoutsList`,
    viewName: 'sviw_O365_MyLayouts2',
    uniqueTable: 'stbv_O365_Layouts',
    fields: getFields(),
    disableLayouts: true,
    allowDelete: true,
    allowUpdate: true,
    whereClause: `[DataObject_ID] = '${props.dataObject.id}' AND [App_ID] = '${app.id}'`
        + ` AND ([Default] = 0)`
        + ` AND [Person_ID] IS NOT NULL`
        + ` AND [Person_ID] <> ${userSession.personId}`
        + ` AND [OrgUnit_ID] IS NULL`
        + ` AND ${registerClause}`
});

if (!dsLayouts.state.isLoaded && !dsLayouts.state.isLoading) {
    dsLayouts.load();
}

if (!dsSharedLayouts.state.isLoaded && !dsSharedLayouts.state.isLoading) {
    dsSharedLayouts.load();
}

useDataObjectEventListener(dsLayouts, 'BeforeDelete', (_pOptions, pRow) => {
    if (pRow.ID === activeLayoutID.value) {
        props.dataObject.layoutManager.resetLayout();
    }
});
useDataObjectEventListener(dsSharedLayouts, 'BeforeDelete', (_pOptions, pRow) => {
    if (pRow.ID === activeLayoutID.value) {
        props.dataObject.layoutManager.resetLayout();
    }
});
useDataObjectEventListener(dsSharedContextLayouts, 'BeforeDelete', (_pOptions, pRow) => {
    if (pRow.Default) {
        props.dataObject.layoutManager.clearDefaultLayout();
    }
    if (pRow.ID === activeLayoutID.value) {
        props.dataObject.layoutManager.resetLayout();
    }
});
useDataObjectEventListener(dsSharedLayouts, 'DataLoaded', (_pOptions, pRow) => {
    checkForSHaredLayoutsCapabilities();
});
useDataObjectEventListener(dsSharedContextLayouts, 'DataLoaded', (_pOptions, pRow) => {
    checkForSHaredLayoutsCapabilities();
});

async function deleteDefaultLayout(pLayout: any) {
    props.dataObject.layoutManager.deleteDefaultLayout(pLayout.OrgUnit_ID).then(() => {
        asyncAlert($t('Layout deleted'), 'success', { autohide: true })
        if (pLayout.ID === activeLayoutID.value) {
            props.dataObject.layoutManager.resetLayout();
        }
        dsSharedContextLayouts.load();
    }).catch((ex) => {
        logger.error(ex);
        if (ex && !ex.Canceled) {
            asyncAlert(ex.message ?? ex);
        }
    });
}

let layoutSharingDeboucne: number | null = null;
async function checkForSHaredLayoutsCapabilities() {
    canShareMap.value = {};
    if (layoutSharingDeboucne) { window.clearTimeout(layoutSharingDeboucne); }
    layoutSharingDeboucne = window.setTimeout(() => {
        sharedLayouts.value.forEach(layout => {
            canShareInPath(layout).then(result => {
                canShareMap.value[layout.ID] = result;
            });
        });
    }, 200);
}

const restrictSharedToContext = ref(false);
if (!dsSharedContextLayouts.state.isLoaded && !dsSharedContextLayouts.state.isLoading) {
    /*
    dsSharedContextLayouts.enableContextFilter({
        idPathField: 'AccessIdPath'
    });
    */
    // dsSharedContextLayouts.filterObject.getItem('Default').selectedValue = 'isfalse';
    // updateSharedContextFilter();

    // context.onChanged(() => {
        // updateSharedContextFilter();
        // dsSharedContextLayouts.filterObject.apply();
    // });
    dsSharedContextLayouts.load();
    // dsSharedContextLayouts.filterObject.apply();
}

//--- Layouts grouped lists ---
const personalLayouts = computed(() => {
    return dsLayouts.data.filter(x => x.OrgUnit_ID == null && x.Person_ID != null);
});

const sharedLayouts = computed(() => {
    return [...dsSharedContextLayouts.data, ...dsSharedLayouts.data];
});

const activeLayoutID = computed(() => {
    return activeLayout.value?.id;
});

async function unshare(pLayout) {
    try {
        isUnsharing.value = true;
        await props.dataObject.layoutManager.unshareLayout(pLayout.ID);
        dsSharedLayouts.remove(pLayout.index);
    } finally {
        isUnsharing.value = false;
    }
}

function layoutIsContextual(pLayout) { return pLayout.dataObjectId === `o_${props.dataObject.id}_sharedContextLayoutsList`; }

async function createNewLayout() {
    isCreatingNewLayout.value = true;
    try {
        const { default: confirm } = await import('o365.controls.confirm.ts');
        const result = await confirm({
            message: $t('Name'),
            title: $t('Create new layout'),
            btnTextOk: $t('Save'),
            btnTextCancel: $t('Cancel'),
            textInputRequired: true
        });
        const name = result.TextInput;
        if (!name) { 
            throw new Error($t(`Layout name can't be empty`));
        }
    await props.dataObject.layoutManager.createNewLayout({
        name: name
    });
    dsLayouts.load();
    } catch (ex) {
        logger.error(ex);
        if (ex && !ex.Canceled) {
            const {default: alert} = await import('o365.controls.alert.ts');
            alert(ex.message);
        }
    } finally {
        isCreatingNewLayout.value = false;
    }
}

let isSettingLayout = false;
function setLayout(layout, fromSharedDs) {
    const id = layout.ID ?? layout.Layout_ID;

    if (isSettingLayout) { return; }
    isSettingLayout = true;
    // if (id === activeLayoutID.value) { return; }
    let options;
    let handler;
    if (fromSharedDs === 'context') {
        options = dsSharedContextLayouts.recordSource.getOptions();
        options.whereClause += ` AND [ID] = ${layout.ID}`;
        handler = dsSharedContextLayouts.dataHandler;
    } else if (fromSharedDs === 'persons') {
        options = dsSharedLayouts.recordSource.getOptions();
        options.whereClause += ` AND [ID] = ${layout.ID}`;
        handler = dsSharedLayouts.dataHandler;
    } else {
        options = dsLayouts.recordSource.getOptions();
        options.whereClause += ` AND [ID] = ${layout.ID}`;
        handler = dsLayouts.dataHandler;
    }
    options.fields.push({name: 'Layout'});
    return handler.request('retrieve', options).then(result => {
        let layout = result[0];            
        props.dataObject.layoutManager?.applyLayout(layout, true);
    }).finally(() => {
        isSettingLayout = false;
    });
}

//--- Shared layout edit mode ---

async function enterEditMode(layout) {
    const id = layout.ID ?? layout.Layout_ID;
    if (id !== activeLayoutID.value) {
        await setLayout(layout, 'context')
        props.dataObject.layoutManager?.enterEditMode(true); 
    } else {
        props.dataObject.layoutManager?.enterEditMode(true);
    }
}

//--- Layout sharing stuff ---

function updateSharedContextFilter() {
    if (restrictSharedToContext.value) {
        dsSharedContextLayouts.filterObject.getItem('AccessIdPath').operator = 'beginswith';
        dsSharedContextLayouts.filterObject.getItem('AccessIdPath').selectedValue = context.idPath;
    } else {
        dsSharedContextLayouts.filterObject.getItem('AccessIdPath').selectedValue = null;
    }
}

function handleRestrictCheckbox(e) {
    restrictSharedToContext.value = e.target.checked;
    updateSharedContextFilter();
    dsSharedContextLayouts.filterObject.apply();
}

const showDefaults = ref(false);
function handleShowDefaultsCheckbox(e) {
    showDefaults.value = e.target.checked;
    if (showDefaults.value) {
        dsSharedContextLayouts.filterObject.getItem('Default').selectedValue = null;
    } else {
        dsSharedContextLayouts.filterObject.getItem('Default').selectedValue = 'isfalse';
    }
     dsSharedContextLayouts.filterObject.apply();
}

const canShare = ref(false);
const canSetDefaults = ref(false);
const canShareMap = ref<Record<number, boolean>>({});
try {
    SharingCapabilitiesChecker.getInstance().canSetDefaultLayouts().then(value => {
        canSetDefaults.value = value;
    });
    SharingCapabilitiesChecker.getInstance().canShare().then(value => {
        canShare.value = value;
        if (canShare.value) {
            handleShowDefaultsCheckbox({ target: { checked: true } });
        }
        dsSharedContextLayouts.allowDelete = value;
        dsSharedContextLayouts.allowUpdate = value;
    });
} catch (ex) {
    logger.error(ex);
}

async function canShareInPath(pLayout: any) {
    if (pLayout.AccessIdPath) {
          return await SharingCapabilitiesChecker.getInstance().canShareInPath(pLayout.AccessIdPath);
    } else {
        return true;
    }
}

//--- Layout renaming ---

const renameModal = ref<OModal|null>(null);
const renamingShared =ref(false);

function openRenameModal(layout, isShared = false) {
    if (isShared) {
        dsSharedContextLayouts.setCurrentIndex(layout.index);
        renamingShared.value = true;
    } else {
        dsLayouts.setCurrentIndex(layout.index);
        renamingShared.value = false;
    }
    renameModal.value?.show();
}

function renameLayout() {
    if (renamingShared.value) {
        dsSharedContextLayouts.recordSource.save();
        if (activeLayout.value && activeLayout.value.id == dsSharedContextLayouts.current?.ID) {
            activeLayout.value.name = dsSharedContextLayouts.current!.Name;
        }
    } else {
        dsLayouts.recordSource.save();
        if (activeLayout.value && activeLayout.value.id == dsLayouts.current?.ID) {
            activeLayout.value.name = dsLayouts.current!.Name;
        }
    }
    renameModal.value?.hide();
}

function renameModalClosed() {
    dsLayouts.cancelChanges();
}

function resetLayout() {
    props.dataObject.layoutManager.resetLayout();
}

function getModulesToInclude() {
    if (!saveSettings.value || saveSettings.value.length === 0) {
        return undefined;
    } else {
        const result = saveSettings.value.filter(x => x.include).map(x => x.key);
        return result.length === 0 ? undefined : result;
    }
}

function save(pSkipCheck = false) {
    // props.dataObject.layoutManager.saveLayout({
        // includedModules: getModulesToInclude()
    // });
    if (!pSkipCheck && activeLayout.value && (!activeLayout.value.id || activeLayout.value.isDefault)) {
        saveAsNew();
        return;
    }
    props.dataObject.layoutManager.saveLayout({
        includedModules: props.dataObject.layoutManager.registeredModules
    });
}

async function saveAsNew() {
    savingAs.value = true;
    const { default: confirm } = await import('o365.controls.confirm.ts');
    try {
        const result = await confirm({
            message: $t('Name'),
            title: $t('Save Layout'),
            btnTextOk: $t('Save'),
            btnTextCancel: $t('Cancel'),
            textInputRequired: true
        });
        const name = result.TextInput;
        if (!name) {
            throw new Error($t("Layout name can't be empty"));
        }
        await props.dataObject.layoutManager.saveLayout({
            includedModules: getModulesToInclude(),
            saveAsNew: true,
            newWithChanges: true,
            name: name
        });
        dsLayouts.load();
    } catch (ex) {
        if (!ex.Canceled) {
            const {default: alert} = await import('o365.controls.alert.ts');
            alert(ex.message);
        }
    } finally {
        savingAs.value = false;
    }
}

async function openPersonShareModal(pLayoutId) {
    personShareLayoutId.value = pLayoutId;
    await nextTick()
    personShareModal.value.show();
}

async function setLayoutAsHidden(pLayout: any) {
    if (!pLayout.Hidden) {
        await procSetLayoutAsHidden.execute({
            ID: pLayout.ID
        });
        pLayout.updateOrExtendItem({Hidden: true});
    } else {
        await procUnsetLayoutAsHidden.execute({
            ID: pLayout.ID
        });
        pLayout.updateOrExtendItem({Hidden: false});
    }
}

async function setLayoutAsDefault(pLayout) {
    if (!pLayout?.ID) { return; }
    await props.dataObject.layoutManager.setLayoutAsDefault(pLayout.ID).then(() => {
        asyncAlert($t('Layout set as default'), 'success', { autohide: true});
        dsSharedContextLayouts.load();
    });
}

const isSettingsVisible = ref(false);
function toggleSaveSettings() {
    const bsCollapse = new window.bootstrap.Collapse(`#${compId.value}_saveConfiguration`);
    isSettingsVisible.value = !isSettingsVisible.value;
}


</script>

<style scoped>
.layout-item {
    width: 100%;
    display: flex;
}

.edit-mode .layout-item.current {
    border: 1px solid rgb(var(--bs-danger-rgb));
}

.layout-item.current button:not(.dropdown-item) {
    font-weight: bold;
}
.layout-item.current button:hover:not(.dropdown-item) {
    font-weight: bold;
}

.dirty-indicator {
    display: none;
    font-size: small;
    margin-left: .25rem;
}
.dirty-layout .layout-item.current .dirty-indicator {
    display: inline-block!important;
}
.layouts-list {
    overflow-y: auto;
    min-height: 32px;
}

.layouts-hoverable {
    display: none;
}
.layout-item:hover .layouts-hoverable {
    display: unset;
}

</style>